// from http://www.w3.org/TR/WCAG20/#relativeluminancedef
export function relativeLuminanceW3C(
  R8bit: number,
  G8bit: number,
  B8bit: number,
) {
  const RsRGB = R8bit / 255
  const GsRGB = G8bit / 255
  const BsRGB = B8bit / 255

  const R =
    RsRGB <= 0.03928 ? RsRGB / 12.92 : Math.pow((RsRGB + 0.055) / 1.055, 2.4)
  const G =
    GsRGB <= 0.03928 ? GsRGB / 12.92 : Math.pow((GsRGB + 0.055) / 1.055, 2.4)
  const B =
    BsRGB <= 0.03928 ? BsRGB / 12.92 : Math.pow((BsRGB + 0.055) / 1.055, 2.4)

  // For the sRGB colorspace, the relative luminance of a color is defined as:
  return 0.2126 * R + 0.7152 * G + 0.0722 * B
}

export function hex2rgb(hex: string): [number, number, number] {
  if (hex.startsWith('#')) {
    hex = hex.slice(1)
  }
  if (hex.length !== 3 && hex.length !== 6) {
    throw new Error('Invaild hex String')
  }

  let digit = hex.split('')

  if (digit.length === 3) {
    digit = [digit[0], digit[0], digit[1], digit[1], digit[2], digit[2]]
  }
  const r = parseInt([digit[0], digit[1]].join(''), 16)
  const g = parseInt([digit[2], digit[3]].join(''), 16)
  const b = parseInt([digit[4], digit[5]].join(''), 16)
  return [r, g, b]
}

export function hexToRGBAColor(hex: string, alpha: number) {
  const hexValue = hex.replace('#', '')
  const r = parseInt(hexValue.substring(0, 2), 16)
  const g = parseInt(hexValue.substring(2, 4), 16)
  const b = parseInt(hexValue.substring(4, 6), 16)
  return `rgba(${r},${g},${b},${alpha / 100})`
}
